import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

const LazyGame = React.lazy(() => import('./Game'));
const LazyAbout = React.lazy(() => import('./About'));

export const routes = [
  {
    path: '/about',
    element: <LazyAbout />,
  },
  {
    path: '/',
    element: <LazyGame />,
  },
];

export function AppRouter(): React.ReactElement {
  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
}
